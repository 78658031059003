import { Button, DatePicker } from "antd";
import moment from "moment"
import helper from 't18helper/dist/fetchHelper';

var obj = {
    "name": "driver",
    "collection": false,
    "displayConfig": {
        "view": true,
        "edit": true,
        "createNew": true,
        "showTable": true,
        "bulkCreate": true,
        "filters": true
    },
    "columns": [
        {

            "title": "Vendor Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            "children": [{}, {}, {}],
            "default": "String -> Any",
            "arr": ["val1", "val2", "val3"],
            "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Phone Number",
            "dataIndex": "phone",
            "key": "phone",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Email",
            "dataIndex": "email",
            "key": "email",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        }
        ,

        {

            "title": "Vehicle Type",
            "dataIndex": "vehicleType",
            "key": "vehicleType",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            // "optional": true,
            // "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        }
        ,
        {

            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            "arr": ["pending", "approved"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        }
        ,
        {

            "title": "Created On",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => moment(val).format("DD-MM-YYYY")

        }
        ,
        {

            "title": "Documents",
            "dataIndex": "media",
            "key": "media",
            "showInTable": true,
            "type": "object",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            disableFields: {

                addressProof: {
                    secure: true
                },
                drivingLicense: {
                    secure: true
                },
                businessInsurance: {
                    secure: true
                }


            },
            fieldsType: {

                addressProof: {
                    url: 'pdf'
                },
                drivingLicense: {
                    url: 'pdf'

                },
                businessInsurance: {
                    url: 'pdf'

                }


            }


        }
        ,
        {

            "title": "Approve",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            disableInView: true,
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val, record) => {

                return (
                    <>
                        {record.status == "pending" && <Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                            onClick={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ id: record._id, "status": "approved" }, "driverStatus")
                                console.log(res)
                                if (res.status === 200) {
                                    helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}>Approve</Button>}

                        {record.status != "pending" && <Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                            onClick={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ id: record._id, "status": "pending" }, "driverStatus")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('Disabled successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error disabling data')

                            }}>Disable</Button>}
                    </>

                );
            }

        },
        {
            "title": "Expiry",
            "dataIndex": "expiry",
            "key": "expiry",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        <p>{val ? moment(val).format('DD MMM,YYYY') : 'no expiry set'}</p>
                        <DatePicker placeholder={'Update expiry'} onChange={(val) => {
                            console.log(val)
                            if (moment().isAfter(val)) {
                                helper.message.error('expiry date cannot be in the past')
                                return
                            }
                            helper.updateData({ id: record._id, expiry: moment(val).toISOString() }, "driverStatus")

                            // helper.updateData({ _id: record._id, expiry: moment(val).toISOString() }, "chefs")
                        }} />
                    </>

                );
            }
        },

        //bankDetails

        {
            "title": "Bank Details",
            "dataIndex": "bankDetails",
            "key": "bankDetails",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                // drop: true,
                // deliverImage: true,
                orderId: true


            },
            fieldsType: {
                // deliverImage: {
                //     url: 'pdf'
                // },
            },
            render: {
                // drop: (val) => addressJoin(val)
            }


        },
    ]

}


export { obj }