import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/dbStore';
import CRUDComp from '../CRUDComp';
import T18Crud from '../common/t18Crud/T18Crud';
import MerchantTabObj from '../../configs/MerchantTabObj';
import DRTabObj from '../../configs/DRTab';

const DRTab = observer(({ props }) => { // replace
    useEffect(() => {
        // dbstore.getData('base'); // replace
    }, [])
    return (

        <T18Crud obj={DRTabObj} />

        // <CRUDComp name="base" columns={columns} data={dbstore.base} {...displayConfig} /> //replace

    );
});
export default DRTab; //replace