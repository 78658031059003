import { Col, Row, Layout, Input, Button, Select, DatePicker, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import helper from 't18helper/dist/fetchHelper';

const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;
const slots = [
    "6 AM - 7 AM", "7 AM - 8 AM", "8 AM - 9 AM", "9 AM - 10 AM", "10 AM - 11 AM", "11 AM - 12 PM",
    "12 PM - 1 PM", "1 PM - 2 PM", "2 PM - 3 PM", "3 PM - 4 PM",
    "4 PM - 5 PM", "5 PM - 6 PM", "6 PM - 7 PM", "7 PM - 8 PM",
    "8 PM - 9 PM", "9 PM - 10 PM", "10 PM - 11 PM"
]

const getStringFromSlot = (slot) => {
    let slotNum = slot;
    if (!slot || slot < 0) return "";
    if (slotNum === 24) slotNum = 12;
    let string = slotNum > 12 ? slotNum - 12 + ' PM - ' + (slotNum - 12 + 1) + ' PM' : slotNum + ' AM - ' + (slotNum + 1) + ' AM';
    console.log(string, slot, 'string');
    if (string == '12 AM - 13 AM') string = '12 PM - 1 PM'
    if (string == '11 AM - 12 AM') string = '11 AM - 12 PM'
    return string;
}
const getSlotFromString = (slot) => {

    slot = slot.slice(0, 5);
    let slotNum = (slot.toLowerCase().includes('a') ? parseInt(slot) : parseInt(slot) + 12);
    if (slotNum === 24) slotNum = 12;
    return slotNum;

}
const TimeSlotSettings = ({ props }) => {
    const [inputData, setinputData] = useState({})
    const [settingsData, setsettingsData] = useState([])
    const getData = async () => {
        var res = await helper.filterData('timeSlotSettings', {})
        // alert(JSON.stringify(res))
        setsettingsData(res.res)
    }
    useEffect(() => {

        getData()
    }, [])
    const handleChange = (key, value) => {
        // console.log(`selected ${value}`);
        var temp = { ...inputData };
        temp[key] = value
        setinputData(temp)
        console.log((temp))
    };

    const handleCreate = async () => {
        if (!inputData.slot) {
            alert('Slot needed')
            return
        }
        if (!inputData.bumpPercentage) {
            alert('Percent needed')

            return
        }
        var res = await helper.createData(inputData, 'timeSlotSettings')
        // alert(JSON.stringify(res))
        getData()

    }

    const handleUpdate = async (slot, percent) => {
        if (percent == null) {
            alert('Percent needed')
            return
        }

        var res = await helper.updateData({ slot: slot, bumpPercentage: percent }, "timeSlotSettings")
        // alert(JSON.stringify(res))
        getData()

    }

    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>
                    <div style={box}>
                        <div>
                            <Select placeholder={"Select Time Zone"} style={{ width: 250 }} onChange={(val) => { handleChange('slot', val) }}>


                                {slots.map((item, index) => {
                                    return <Option value={getSlotFromString(item)}>{item}</Option>;
                                })}
                            </Select>
                            <Input
                                onChange={(e) => {
                                    handleChange('bumpPercentage', e.target.value)
                                }}
                                type="number"
                                placeholder='Enter Bump Percentage' style={{ width: 250, marginLeft: 30, marginRight: 30 }} />
                            <Button
                                onClick={() => {

                                    handleCreate()
                                }}
                                type='primary'>Create</Button>

                        </div>
                    </div>
                </Col>
                <Col span={24}>
                    <div style={box}>
                        <div style={{ borderBottom: '.1px solid #ccc', padding: 20, width: 350 }}>
                            <span style={{ width: 80, display: 'inline-block', fontWeight: 'bold' }}>Slot</span>
                            <span style={{ marginLeft: 30, marginRight: 30, fontWeight: 'bold' }}>Percentage</span>
                            <span style={{ fontWeight: 'bold' }}>Edit</span>



                        </div>
                        {settingsData.map((item, index) => {
                            var percent = null;
                            return <div style={{ borderBottom: '.1px solid #ccc', padding: 20, width: 350 }}>
                                <span style={{ width: 80, display: 'inline-block' }}>{getStringFromSlot(item.slot)}</span>
                                <Input onChange={(e) => {
                                    percent = e.target.value
                                }} defaultValue={item.bumpPercentage} style={{ width: 60, marginLeft: 30, marginRight: 30 }} />
                                <Button
                                    onClick={async () => {
                                        // alert(percent)
                                        handleUpdate(item.slot, percent)
                                    }} size='small' type='primary'>Update </Button>

                            </div>;
                        })}
                    </div>
                </Col>

            </Row>
        </>
    );
};
export default TimeSlotSettings;