import { Button, Popconfirm, Modal, Dropdown, Space, Input } from "antd";
import moment from "moment"
import helper from 't18helper/dist/fetchHelper';
import { DownOutlined } from '@ant-design/icons';
import { useState } from "react";
import { drop } from "lodash";

var getStringFromSlot = (slot) => {
    let slotNum = slot;
    if (!slot || slot < 0) return "";
    if (slotNum === 24) slotNum = 12;
    let string = slotNum > 12 ? slotNum - 12 + ' PM - ' + (slotNum - 12 + 1) + ' PM' : slotNum + ' AM - ' + (slotNum + 1) + ' AM';
    if (slotNum == 12) {
        string = '12 PM - 1 PM'
    }
    if (slotNum == 23) {
        string = '11 PM - 12 AM'
    }
    console.log(string, slot, 'string');
    return string;
}
var addressJoin = (obj) => {
    // alert(JSON.stringify(obj))
    let address = "";
    address += obj?.line_1 ? obj.line_1 + ", " : "";
    address += obj?.line_2 ? obj.line_2 + ", " : "";
    address += obj?.city ? obj.line_3 + ", " : "";
    address += obj?.country ? obj.country + ", " : "";
    address += obj?.postcode ? obj.postcode : "";
    return address;
}

const CancelInfopopup = ({ record }) => {
    // alert('hello')
    const [active, seActive] = useState(false)
    const [dropdownval, setDropDownVal] = useState(record.cancelledBy ? record.cancelledBy : 'Canceled By')
    var saveButtonStat = false
    const setSaveButttonStat = () => {
        if (record.merchantRefund && record.driverRefund) {
            if (record.merchantRefund.status == 'paid' && record.driverRefund.status == 'paid') {
                saveButtonStat = true
            }
        }

        console.log(record)
    }
    setSaveButttonStat()
    const items = [
        {
            label: <div onClick={() => { setDropDownVal('admin') }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>Admin</div>,
            key: '0',
        },
        {
            label: <div onClick={() => { setDropDownVal('merchant') }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>Merchant</div>,
            key: '1',
        },
        {
            label: <div onClick={() => { setDropDownVal('driver') }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>Driver</div>,
            key: '3',
        },
    ];

    const data = {
        "canceledBy": dropdownval,
        "merchantRefund": record.merchantRefund ? record.merchantRefund.amount : null,
        "driverRefund": record.driverRefund ? record.driverRefund.amount : null
    }
    return (
        <>
            <Button onClick={() => { seActive(true); console.log(record) }} type="primary" size="small" style={{ borderColor: 'orange', color: 'orange' }} ghost
            >Cancel Info</Button>
            <Modal
                title="Cancel Info"
                centered
                open={active}
                onOk={async () => {
                    console.log(record._id)
                    if (data.canceledBy == 'Canceled By') {
                        alert('Select canceled by from dropdown')
                        return;
                    }

                    if (record.merchantRefund || record.driverRefund) {
                        if (data.merchantRefund.length == 0 || data.driverRefund.length == 0) {
                            alert('Enter Valid Refund Data')
                            return;
                        }

                    }


                    let res = await helper.updateData({ id: record._id, "cancelledBy": data.canceledBy, merchantRefund: { amount: data.merchantRefund, status: record.merchantRefund ? record.merchantRefund.status : 'pending' }, driverRefund: { amount: data.driverRefund, status: record.driverRefund ? record.driverRefund.status : 'pending' } }, "deliveryRequest")
                    console.log(res)
                    if (res.status === 200) {
                        window.location.reload();
                        return res
                    }
                    return helper.message.error('error approving data')
                    seActive(false);
                }
                }
                onCancel={() => seActive(false)}
                width={500} merchantRefund
                okText="save"
                okButtonProps={{
                    disabled: saveButtonStat
                }}

            >
                <div style={{ width: '57%', height: 35, marginLeft: '100px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#cccaca', marginBottom: '20px', borderRadius: 5, }}>
                    <Dropdown overlayStyle={{}} menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ fontSize: 18, color: 'black', fontWeight: 'bold', paddingLeft: '10px', opacity: dropdownval == 'Canceled By' ? 0.5 : null, width: '240px' }}>
                                        {dropdownval}

                                    </div>
                                    <div>
                                        <DownOutlined style={{ marginLeft: 0, fontWeight: 'bold' }} size={20} />
                                    </div>

                                </div>

                            </Space>
                        </a>
                    </Dropdown>
                </div>

                <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: '100px', paddingRight: '100px' }}>
                    <div style={{ color: 'grey', fontSize: 15, marginTop: 0, marginBottom: 5, width: '100%' }}>
                        Merchant Refund
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input placeholder="Merchant Refund" size="large" onChange={(val) => { console.log(val.target.value); data.merchantRefund = val.target.value }} defaultValue={record.merchantRefund ? String(record.merchantRefund.amount) : null} />
                        {
                            record.merchantRefund ?
                                <>
                                    {
                                        record.merchantRefund.status == 'paid' ?
                                            <div
                                                style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', opacity: 0.5 }}>
                                                <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                                    Paid
                                                </div>
                                            </div>
                                            :
                                            <button
                                                disabled={record.cancelledBy ? false : true}
                                                onClick={async () => {

                                                    if (record.merchantRefund.status == true) {
                                                        if (record.merchantRefund.status == 'paid') {
                                                            return;
                                                        }
                                                    }
                                                    let res = await helper.updateData({ id: record._id, "cancelledBy": data.canceledBy, merchantRefund: { amount: data.merchantRefund, status: 'paid' }, driverRefund: { amount: data.driverRefund, status: record.driverRefund ? record.driverRefund.status : 'pending' } }, "deliveryRequest")
                                                    console.log(res)
                                                    if (res.status === 200) {
                                                        window.location.reload();
                                                        return res
                                                    }
                                                    return helper.message.error('error approving data')
                                                }}
                                                style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', opacity: record.cancelledBy ? 1 : 0.5 }}>
                                                <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                                    Paid
                                                </div>
                                            </button>
                                    }
                                </>
                                :
                                <button
                                    disabled={record.cancelledBy ? false : true}
                                    onClick={async () => {

                                        if (record.merchantRefund.status == true) {
                                            if (record.merchantRefund.status == 'paid') {
                                                return;
                                            }
                                        }
                                        let res = await helper.updateData({ id: record._id, "cancelledBy": data.canceledBy, merchantRefund: { amount: data.merchantRefund, status: 'paid' }, driverRefund: { amount: data.driverRefund, status: record.driverRefund ? record.driverRefund.status : 'pending' } }, "deliveryRequest")
                                        console.log(res)
                                        if (res.status === 200) {
                                            window.location.reload();
                                            return res
                                        }
                                        return helper.message.error('error approving data')
                                    }}
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', opacity: record.cancelledBy ? 1 : 0.5 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </button>
                        }

                    </div>
                    <div style={{ height: '20px' }}></div>
                    <div style={{ color: 'grey', fontSize: 15, marginTop: 0, marginBottom: 5, width: '100%' }}>
                        Driver Refund
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input placeholder="Driver Refund" size="large" onChange={(val) => { console.log(val.target.value); data.driverRefund = val.target.value }} defaultValue={record.driverRefund ? String(record.driverRefund.amount) : null} />
                        {
                            record.driverRefund ?
                                <>
                                    {
                                        record.driverRefund.status == 'paid' ?
                                            <div
                                                style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', opacity: 0.5 }}>
                                                <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                                    Paid
                                                </div>
                                            </div>
                                            :
                                            <button
                                                disabled={record.cancelledBy ? false : true}
                                                onClick={async () => {

                                                    if (record.driverRefund.status == true) {
                                                        if (record.driverRefund.status == 'paid') {
                                                            return;
                                                        }
                                                    }
                                                    let res = await helper.updateData({ id: record._id, "cancelledBy": data.canceledBy, merchantRefund: { amount: data.merchantRefund, status: record.merchantRefund ? record.merchantRefund.status : 'pending' }, driverRefund: { amount: data.driverRefund, status: 'paid' } }, "deliveryRequest")
                                                    console.log(res)
                                                    if (res.status === 200) {
                                                        window.location.reload();
                                                        return res
                                                    }
                                                    return helper.message.error('error approving data')
                                                }}
                                                style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', opacity: record.cancelledBy ? 1 : 0.5 }}>
                                                <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                                    Paid
                                                </div>
                                            </button>
                                    }
                                </>
                                :
                                <button
                                    disabled={record.cancelledBy ? false : true}
                                    onClick={async () => {

                                        if (record.driverRefund.status == true) {
                                            if (record.driverRefund.status == 'paid') {
                                                return;
                                            }
                                        }
                                        let res = await helper.updateData({ id: record._id, "cancelledBy": data.canceledBy, merchantRefund: { amount: data.merchantRefund, status: record.merchantRefund ? record.merchantRefund.status : 'pending' }, driverRefund: { amount: data.driverRefund, status: 'paid' } }, "deliveryRequest")
                                        console.log(res)
                                        if (res.status === 200) {
                                            window.location.reload();
                                            return res
                                        }
                                        return helper.message.error('error approving data')
                                    }}
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: '#0737a8', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', opacity: record.cancelledBy ? 1 : 0.5 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </button>
                        }
                    </div>


                </div>
            </Modal>
        </>
    )

}

var DRTabObj = {
    "name": "deliveryRequest",
    "collection": false,
    "displayConfig": {
        "view": true,
        "edit": false,
        "createNew": false,
        "showTable": true,
        "bulkCreate": false,
        "filters": true
    },
    "columns": [
        {

            "title": "DR ID",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Created On",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": false,
            "type": "date",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => moment(val).format("DD-MM-YYYY")

        },
        {

            "title": "Deliver On",
            "dataIndex": "date",
            "key": "date",
            "showInTable": true,
            "type": "date",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => moment(val).format("DD-MM-YYYY")

        },

        {

            "title": "Delivery Slot",
            "dataIndex": "deliverySlot",
            "key": "deliverySlot",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => getStringFromSlot(val)

        },

        {

            "title": "Pickup Address",
            "dataIndex": "pickup",
            "key": "pickup",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => addressJoin(val)

        },

        {

            "title": "Packages",
            "dataIndex": "packages",
            "key": "packages",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => val.length || 0

        },
        {

            "title": "Merchant Name",
            "dataIndex": "merchantName",
            "key": "merchantName",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],


        },
        {

            "title": "Merchant Price",
            "dataIndex": "estimatedPrice",
            "key": "estimatedPrice",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            "render": (val) => {
                if (val) {
                    return val.toFixed(2)
                } else {
                    return null
                }
            }

        },
        {

            "title": "Driver Name",
            "dataIndex": "driverName",
            "key": "driverName",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],


        },
        {

            "title": "Driver Id",
            "dataIndex": "driverId",
            "key": "driverId",
            "showInTable": false,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],


        },
        {

            "title": "Driver Price",
            "dataIndex": "estimatedDriverPrice",
            "key": "estimatedDriverPrice",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            "render": (val) => {
                if (val) {
                    return val.toFixed(2)
                } else {
                    return null
                }
            }

        },
        {

            "title": "Distance",
            "dataIndex": "estimatedDistance",
            "key": "estimatedDistance",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            "render": (val) => {
                if (val) {
                    return val.toFixed(2)
                } else {
                    return null
                }
            }



        },
        {

            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "completed": "green", "created": "orange", "expired": "red" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            "arr": ["completed", "created", 'expired'],
            // "values": ["id1", "id2", "id3"],
            // "render": function (val, record) {
            //     return record.status
            // }

        },
        {
            "title": "Order Actions",
            "dataIndex": "temp",
            "key": "temp",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.status != "cancelled" && record.status != "completed" && <Popconfirm
                            title="Are you sure want to cancel?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ id: record._id, status: 'cancelled', merchantRefund: { amount: 0, status: 'pending' }, driverRefund: { amount: 0, status: 'pending' } }, "deliveryRequest")

                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();
                                    return res
                                }
                                // return helper.message.error('error approving data')
                                // console.log(res1)
                                // if (res1.status === 200) {
                                //     window.location.reload();
                                //     return res1
                                // }
                                // return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Cancel</Button></Popconfirm>}
                    </>

                );
            }
        },
        {

            "title": "Cancel Info",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "completed": "green", "created": "orange", "expired": "red" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            "arr": ["completed", "created", 'expired'],
            // "values": ["id1", "id2", "id3"],
            "render": (val, record) => {

                return (
                    <>
                        {
                            record.status == "cancelled" ?
                                <CancelInfopopup
                                    record={record}
                                />
                                :
                                null
                        }
                    </>
                )

            }

        },
        {
            "title": "Packages",
            "dataIndex": "packages",
            "key": "packages",
            "showInTable": false,
            "type": "array",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                // drop: true,
                // deliverImage: true,
                orderId: true


            },
            fieldsType: {
                deliverImage: {
                    url: 'pdf'
                }, returnImage: {
                    url: 'pdf'
                },
            },
            render: {
                drop: (val) => addressJoin(val),
                EstimatedDriverPrice: (val) => Number(val).toFixed(2),
                EstimatedMerchantPrice: (val) => {
                    // alert(val)
                    return Number(val).toFixed(2)
                    return 0
                }
            }


        },
        {
            "title": "Merchent Refund",
            "dataIndex": "merchantRefund",
            "key": "merchantRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            render: function (val, record) {
                return record.merchantRefund.amount || "-"
            }
        },
        {
            "title": "Merchent Refund Status",
            "dataIndex": "merchantRefund",
            "key": "merchantRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            render: function (val, record) {
                if (record.merchantRefund.status == "paid" && record.merchantRefund.amount >= 0) {
                    return 'paid'
                }
                else if (record.merchantRefund.status == "pending" && record.merchantRefund.amount >= true) {
                    return 'saved'
                }
                else {
                    return 'not updated'
                }
            }
        },
        {
            "title": "Driver Refund",
            "dataIndex": "driverRefund",
            "key": "driverRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            render: function (val, record) {
                return record.driverRefund.amount || "-"
            }
        },
        {
            "title": "Driver Refund Status",
            "dataIndex": "driverRefund",
            "key": "driverRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            render: function (val, record) {
                if (record.driverRefund.status == "paid" && record.driverRefund.amount >= 0) {
                    return 'paid'
                }
                else if (record.driverRefund.status == "pending" && record.driverRefund.amount >= true) {
                    return 'saved'
                }
                else {
                    return 'not updated'
                }
            }
        }
    ]

}


export default DRTabObj