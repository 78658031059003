import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import ContactUs from "../screens/ContactUs";
import DriverPayments from "../screens/DriverPayments";

import DriverScreen from "../screens/DriverScreen";
import DRScreen from "../screens/DRScreen";
import MerchantScreen from "../screens/MerchantScreen";
import SettingsScreen from "../screens/SettingsScreen";
const RouteSwitcher = (props) => {
    let { path, url } = useRouteMatch();

    return (
        <Switch>

            <Route path="/drivers" exact> <DriverScreen /></Route>
            <Route path="/merchants" exact> <MerchantScreen /></Route>
            <Route path="/deliveryRequests" exact> <DRScreen /></Route>
            <Route path="/settings" exact> <SettingsScreen /></Route>
            <Route path="/driverpayments" exact> <DriverPayments /></Route>
            <Route path="/contactus" exact> <ContactUs /></Route>


        </Switch>
    );
};

export default RouteSwitcher;
