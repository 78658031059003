import { Col, Row, Layout, Input, Select, Button, DatePicker, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import helper from 't18helper/dist/fetchHelper';

const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;

const ZipCodeSettings = ({ props }) => {
    const [inputData, setinputData] = useState({})
    const [settingsData, setsettingsData] = useState([])
    const getData = async () => {
        var res = await helper.filterData('zipCodeSettings', {})
        // alert(JSON.stringify(res))
        setsettingsData(res.res)
    }
    useEffect(() => {

        getData()
    }, [])
    const handleChange = (key, value) => {
        // console.log(`selected ${value}`);
        var temp = { ...inputData };
        temp[key] = value
        setinputData(temp)
        console.log((temp))
    };

    const handleCreate = async () => {
        if (!inputData.zipCode) {
            alert('Slot needed')
            return
        }
        if (!inputData.bumpPercentage) {
            alert('Percent needed')

            return
        }
        var res = await helper.createData(inputData, 'zipCodeSettings')
        // alert(JSON.stringify(res))
        getData()

    }

    const handleUpdate = async (zipCode, percent) => {
        if (percent == null) {
            alert('Percent needed')
            return
        }

        var res = await helper.updateData({ zipCode: zipCode, bumpPercentage: percent }, "zipCodeSettings")
        // alert(JSON.stringify(res))
        getData()

    }
    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>
                    <div style={box}>
                        <div>
                            <Input
                                onChange={(e) => {
                                    handleChange('zipCode', e.target.value)
                                }}
                                placeholder='Enter ZipCode' style={{ width: 250 }} />
                            <Input
                                type={'number'}
                                onChange={(e) => {
                                    handleChange('bumpPercentage', e.target.value)
                                }}
                                placeholder='Enter Bump Percentage' style={{ width: 250, marginLeft: 30, marginRight: 30 }} />
                            <Button
                                onClick={handleCreate}
                                type='primary'>Create</Button>

                        </div>
                    </div>
                </Col>

                <Col span={24}>
                    <div style={box}>
                        <div style={{ borderBottom: '.1px solid #ccc', padding: 20, width: 350 }}>
                            <span style={{ width: 80, display: 'inline-block', fontWeight: 'bold' }}>ZipCode</span>
                            <span style={{ marginLeft: 30, marginRight: 30, fontWeight: 'bold' }}>Percentage</span>
                            <span style={{ fontWeight: 'bold' }}>Edit</span>



                        </div>
                        {settingsData.map((item, index) => {
                            var percent = null;
                            return <div style={{ borderBottom: '.1px solid #ccc', padding: 20, width: 350 }}>
                                <span style={{ width: 80, display: 'inline-block' }}>{item.zipCode}</span>
                                <Input onChange={(e) => {
                                    percent = e.target.value
                                }} defaultValue={item.bumpPercentage} style={{ width: 60, marginLeft: 30, marginRight: 30 }} />
                                <Button
                                    onClick={async () => {
                                        // alert(percent)
                                        handleUpdate(item.zipCode, percent)
                                    }} size='small' type='primary'>Update </Button>

                            </div>;
                        })}
                    </div>
                </Col>

            </Row>
        </>
    );
};
export default ZipCodeSettings;