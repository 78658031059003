import { Col, Row, Layout, Input, Select, DatePicker, Tabs, Button } from 'antd'
import { useEffect, useState } from 'react'
import DRTab from './DRTab';
import helper from 't18helper/dist/fetchHelper';
import _ from 'lodash';
import MerchantTab from './MerchantTab';
const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;

const DefaultSettings = ({ props }) => {
    const [settingsData, setsettingsData] = useState({
        "comission": {
            "small": {
                "baseCommission": '',
                "commissionPerHalfMile": ''
            },
            "medium": {
                "baseCommission": '',
                "commissionPerHalfMile": ''
            },
            "large": {
                "baseCommission": '',
                "commissionPerHalfMile": ''
            }
        },
        "pricing": {
            "small": {
                "basePrice": '',
                "pricePerHalfMile": ''
            },
            "medium": {
                "basePrice": '',
                "pricePerHalfMile": ''
            },
            "large": {
                "basePrice": '',
                "pricePerHalfMile": ''
            }
        }
    })
    useEffect(() => {
        const run = async () => {
            var res = await helper.filterData('settings', {})
            // alert(JSON.stringify(res))
            setsettingsData(res.res)
        }
        run()
    }, [])
    var handleChange = (path, value) => {
        var temp = { ...settingsData }
        _.set(temp, path, value)
        // alert(JSON.stringify(temp))
        setsettingsData(temp)
    }
    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={12}>
                    <div style={box}>
                        <h1>Comission:</h1>
                        <div>
                            <h2>
                                Small:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Comission:</span>
                                <Input value={settingsData.comission.small.baseCommission}
                                    onChange={(e) => { handleChange('comission.small.baseCommission', e.target.value) }}
                                    placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Comission Per Half Mile:</span>
                                <Input value={settingsData.comission.small.commissionPerHalfMile}
                                    onChange={(e) => { handleChange('comission.small.commissionPerHalfMile', e.target.value) }}

                                    placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>
                        <div>
                            <h2>
                                Medium:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Comission:</span>
                                <Input
                                    onChange={(e) => { handleChange('comission.medium.baseCommission', e.target.value) }}

                                    value={settingsData.comission.medium.baseCommission} placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Comission Per Half Mile:</span>
                                <Input
                                    onChange={(e) => { handleChange('comission.medium.commissionPerHalfMile', e.target.value) }}

                                    value={settingsData.comission.medium.commissionPerHalfMile} placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>
                        <div>
                            <h2>
                                Large:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Comission:</span>
                                <Input
                                    onChange={(e) => { handleChange('comission.large.baseCommission', e.target.value) }}

                                    value={settingsData.comission.large.baseCommission} placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Comission Per Half Mile:</span>
                                <Input
                                    onChange={(e) => { handleChange('comission.large.commissionPerHalfMile', e.target.value) }}

                                    value={settingsData.comission.large.commissionPerHalfMile} placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>

                    </div>
                </Col>

                <Col span={12}>
                    <div style={box}>
                        <h1>Pricing:</h1>
                        <div>
                            <h2>
                                Small:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Pricing:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.small.basePrice', e.target.value) }}

                                    value={settingsData.pricing.small.basePrice} placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Pricing Per Half Mile:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.small.pricePerHalfMile', e.target.value) }}

                                    value={settingsData.pricing.small.pricePerHalfMile} placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>
                        <div>
                            <h2>
                                Medium:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Pricing:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.medium.basePrice', e.target.value) }}

                                    value={settingsData.pricing.medium.basePrice} placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Pricing Per Half Mile:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.medium.pricePerHalfMile', e.target.value) }}

                                    value={settingsData.pricing.medium.pricePerHalfMile} placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>
                        <div>
                            <h2>
                                Large:
                            </h2>
                            <div style={{ flexDirection: 'column', marginBottom: 20 }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block' }}>Base Pricing:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.large.basePrice', e.target.value) }}

                                    value={settingsData.pricing.large.basePrice} placeholder='Base Comission' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <span style={{ fontSize: 16, width: 200, display: 'inline-block', marginBottom: 20 }}>Pricing Per Half Mile:</span>
                                <Input
                                    onChange={(e) => { handleChange('pricing.large.pricePerHalfMile', e.target.value) }}

                                    value={settingsData.pricing.large.pricePerHalfMile} placeholder='Comission Per Half Mile' inputMode='decimal' style={{ width: 200, marginLeft: 10 }} />
                            </div>
                        </div>

                    </div>
                </Col>
                <Col span={24} style={{ marginLeft: 50 }}>
                    <Button
                        onClick={async () => {
                            var res = await helper.createData(settingsData, 'settings')
                            // alert(JSON.stringify(res))
                        }} size='large' type='primary'>Update All</Button>

                </Col>


            </Row>
        </>
    );
};
export default DefaultSettings;