import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useLocation, NavLink
} from "react-router-dom";
import { Row, Col, Image } from 'antd'
import T18NavLink from "./NavLink";

import logo from '../../assets/Logo.png'

const SideNav = (props) => {

  return (
    <Row style={{ backgroundColor: '#fff', width: '100%', height: '100vh' }} className="t18_sidenav">
      <Col span={24} >
        {/* <Image src={require('../../assets/Logo.png')} /> */}
        <Row style={{ justifyContent: 'center', alignItems: 'center', padding: 30, backgroundColor: '#0854A922', marginBottom: 50 }}>
          <img src={logo} alt="logo" width="100%" />

        </Row>

        <T18NavLink to="/drivers" title={"Drivers"}></T18NavLink>
        <T18NavLink to="/merchants" title={"Merchants"}></T18NavLink>
        <T18NavLink to="/deliveryRequests" title={"Delivery Requests"}></T18NavLink>
        <T18NavLink to="/settings" title={"Settings"}></T18NavLink>
        <T18NavLink to="/driverpayments" title={"Driver Payments"}></T18NavLink>
        <T18NavLink to="/contactus" title={"Contact Us"}></T18NavLink>

      </Col>
    </Row>
  );
};

export default SideNav;
