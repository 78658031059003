import React, { useEffect } from 'react';

import { Button, Row, Col, Layout, Affix, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import SideNav from "./components/nav/SideNav";
import TopNav from "./components/nav/TopNav";
import RouteSwitcher from "./components/nav/routeSwitcher";
import Login from './components/common/LoginScreen';
import "antd/dist/antd.compact.less";
import './App.css';
import './T18.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { dbstore } from './dataLayer/dbStore';
import { observer } from 'mobx-react';
import helper from 't18helper/dist/fetchHelper';
// import helper from 't18helper/src/fetchHelper';

import urls from './configs/urls';
// import helper from './dataLayer/fetchHelper';

// import "antd/dist/antd.css";
// import './App.css';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const App = observer(() => {
  // helper.setData('endpoint', "http://localhost:3000");
  helper.setData('endpoint', "https://api.deliver-nearby.co.uk");
  helper.setData('urls', urls)
  helper.setData('message', message)
  helper.setData('setLoadingIndicator', dbstore.setLoading)
  useEffect(() => {

    // helper.message.success('Hurray!')
    console.log(helper.endpoint)
    dbstore.refreshStore()
  }, [])
  return <>


    <Router >
      {!dbstore.login && <Login name="DeliverNearby" primaryColor="#0753A9" />}
      {dbstore.login && <Row>
        <Col span={4}>
          <Affix>
            <SideNav />
          </Affix>
        </Col>
        <Col span={20}>
          <Row>
            <Col span={24}>
              <Affix>
                <TopNav />
              </Affix>
            </Col>
          </Row>
          <Spin
            tip="Loading..."
            spinning={dbstore.loading}
            // spinning={false}

            indicator={antIcon}
          >
            <Row style={{
              padding: 50,
              paddingTop: 20,
              paddingRight: 70,
              paddingLeft: 70,

              backgroundColor: "#efeff4",
              minHeight: '100vh'
            }}>
              <RouteSwitcher />
            </Row></Spin>

        </Col>
      </Row>}
    </Router>

  </>
});

export default App;