import { Col, Row, Layout, Input, Select, DatePicker, Tabs } from 'antd'
import { useEffect, useState } from 'react'

import MerchantTab from './MerchantTab';
const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;

const MerchantScreen = ({ props }) => {
    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>
                    <div style={box}>
                        <Tabs defaultActiveKey="1"  >
                            <TabPane tab="Merchants" key="1"> <MerchantTab /></TabPane>
                        </Tabs>
                    </div>
                </Col>

            </Row>
        </>
    );
};
export default MerchantScreen;