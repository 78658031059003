import { Button, Popconfirm } from "antd";
import moment from "moment"
import helper from 't18helper/dist/fetchHelper';

var MerchantTabObj = {
    "name": "merchant",
    "collection": false,
    "displayConfig": {
        "view": true,
        "edit": true,
        "createNew": true,
        "showTable": true,
        "bulkCreate": true,
        "filters": true
    },
    "columns": [
        {

            "title": "Vendor Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            "children": [{}, {}, {}],
            "default": "String -> Any",
            "arr": ["val1", "val2", "val3"],
            "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Business Name",
            "dataIndex": "businessName",
            "key": "businessName",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            // "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },
        {

            "title": "Balance",
            "dataIndex": "balance",
            "key": "balance",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            // "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => {
                val = val.toFixed(2)
                return '£ ' + val
            }
        },
        {

            "title": "Phone Number",
            "dataIndex": "phone",
            "key": "phone",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        },

        {

            "title": "Email",
            "dataIndex": "email",
            "key": "email",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        }
        ,
        {

            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            "arr": ["pending", "approved"],
            // "values": ["id1", "id2", "id3"],
            // "render": "Function"

        }
        ,
        {

            "title": "Created On",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => moment(val).format("DD-MM-YYYY")

        }
        ,
        {

            "title": "Documents",
            "dataIndex": "media",
            "key": "media",
            "showInTable": true,
            "type": "object",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            disableFields: {

                addressProof: {
                    secure: true
                },
                drivingLicense: {
                    secure: true
                },
                businessInsurance: {
                    secure: true
                }


            },
            fieldsType: {

                addressProof: {
                    url: 'pdf'
                },
                drivingLicense: {
                    url: 'pdf'

                },
                businessInsurance: {
                    url: 'pdf'

                }
                , businessProof: {
                    url: 'pdf'

                }

            }


        }
        ,

        {

            "title": "Approve",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "colors": { "pending": "red", "approved": "blue" },
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val, record) => {

                return (
                    <>
                        {record.status == "pending" && <Popconfirm
                            title="Are you sure want to approve?"

                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ id: record._id, "status": "approved" }, "merchantStatus")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Approve</Button></Popconfirm>}

                        {record.status != "pending" && <Popconfirm
                            title="Are you sure want to disable?"

                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ id: record._id, "status": "pending" }, "merchantStatus")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('Disabled successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error disabled data')

                            }}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                        >Disable</Button></Popconfirm>}
                    </>

                );
            }

        }
    ]

}


export default MerchantTabObj