import {
    makeObservable,
    makeAutoObservable,
    observable,
    computed,
    action,
    flow,
    toJS,
    runInAction,
} from "mobx";
import moment from "moment";
import Cookies from 'js-cookie'
import { message } from "antd";
import obj from '../configs/urls';
import helper from 't18helper/dist/fetchHelper';

import _ from "lodash";
const endpoint = "http://localhost:3001";
const apiKey = "X4oXYJAT5d46CubVOI8Yjg==";
var getUrls = obj.getUrls;
var postUrls = obj.postUrls;
var putUrls = obj.putUrls;
export default class dbStore {
    endpoint = endpoint;
    loading = false;
    login = false

    setLoading(loading) {
        this.loading = loading;
    }

    constructor() {
        for (var key in getUrls) {
            this[key] = []
        }
        makeAutoObservable(this, {
        });
    }

    *getData(key, refresh = false) {
        this.loading = true;
        var path = getUrls[key]
        var options = {
            method: "get",

            // credentials: 'include'
            headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?limit=100`,
            options,

        );
        res = yield res.json();
        this[key] = res;
        if (key + 's' in res) {
            this[key] = res[key + 's']
        }
        this.loading = false;
        return res;
    }

    *filterData(key, obj, refresh = false) {
        console.log(obj)
        this.loading = true;
        var path = getUrls[key]
        var str = '?'
        for (var k in obj) {
            console.log(obj[k])
            if (obj[k] == "") { continue }
            if (!obj[k]) { continue }
            str = str + k + "=" + obj[k] + "&"
        }
        path += str
        console.log(path)
        var options = {
            method: "get",

            // credentials: 'include'
            headers: { "Content-Type": "application/json" },
        }
        if (refresh) {
            options['cache'] = "no-cache"
        }
        var res = yield fetch(
            `${endpoint}${path}?limit=100`,
            options,

        );
        res = yield res.json();
        this[key] = res;
        if (key + 's' in res) {
            this[key] = res[key + 's']
        }
        this.loading = false;
        return res;
    }

    *create(obj, key, returnVal = false) {
        this.loading = true;
        var path = postUrls[key];
        var raw = {
            ...obj
        }
        console.log(raw, JSON.stringify(raw))
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "post",
                // cache: "no-cache",
                credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw),
                redirect: 'follow'

            },

        );
        var status = res.status;
        res = yield res.json();
        console.log(res);
        if (status == 200) {
            message.success(key + " Created Successfully")
            this.getData(key)
        } else {
            message.error("Oops! something went wrong. Try again later.")
        }
        this.loading = false;
        if (returnVal) {
            return res
        } else {
            return status;
        }
    }

    *update(obj, key) {
        this.loading = true;
        var path = putUrls[key];
        var raw = {
            ...obj
        }
        console.log(raw, JSON.stringify(raw))
        var res = yield fetch(
            `${endpoint}${path}`,
            {
                method: "put",
                // cache: "no-cache",
                // credentials: 'include',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(raw),
                redirect: 'follow'

            },

        );
        var status = res.status;
        res = yield res.json();
        console.log(res);
        if (status == 200) {
            message.success(key + " Updated Successfully")
            this.getData(key)
        } else {
            message.error("Oops! something went wrong. Try again later.")
        }
        this.loading = false;
        return status;
    }

    *generateAndUpload(file, secure, fileType = "png") {
        this.loading = true
        var url = yield this.create({
            "fileType": fileType,
            "secure": secure
        }, 'uploadUrl', true);

        var res = yield this.uploadFile(url.url, file)
        if (res == 200) {
            return { url: url.url.split("?")[0], secure: secure }

        } else {
            return { url: 'error', secure: secure }
        }

    }

    *uploadFile(url, file) {
        this.loading = true;
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "image/png");
        var res = yield fetch(url, {
            method: "PUT",
            body: file,
            headers: myHeaders,
            redirect: "follow",
        })
        console.log(res, url)
        var status = res.status;
        this.loading = false;

        return status;
    }

    *loginFunc(email, password) {
        console.log('login ', email, password)

        var raw = {
            email: email,
            password: password
        }
        var res = yield fetch(
            `https://services.neutrodev.com/users/login`,
            {
                method: "post",
                headers: { "Content-Type": "application/json", "apikey": "FB3Lc8N0Q7ti7Mu3N6tWVQ==" },
                // credentials: 'include',
                body: JSON.stringify(raw)
            }
        );

        var status = res.status;
        res = yield res.json();
        console.log(res, status);
        if (status == 200) {
            console.log("login suc")
            this.login = true;
            this.globalRefresh = true;
            yield helper.createData(res, 'token')

            // yield this.create(res, 'token')
            // yield this.getData(true)

        }
        return status;
    }

    refreshStore() {


        this.login = Cookies.get('login') == 'true'
        console.log(this.login)
    }




}

const dbstore = new dbStore();
export { dbstore };