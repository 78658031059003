import { Col, Row, Layout, Input, Select, DatePicker, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import DefaultSettings from './DefaultSettings';
import DRTab from './DRTab';

import MerchantTab from './MerchantTab';
import TimeSlotSettings from './TimeSlotSettings';
import helper from 't18helper/dist/fetchHelper';
import ZipCodeSettings from './ZipCodeSettings';
const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;

const SettingsScreen = ({ props }) => {

    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>
                    <div style={box}>
                        <Tabs defaultActiveKey="1"  >
                            <TabPane tab="Default Settings" key="1"> <DefaultSettings /></TabPane>
                            <TabPane tab="Time Slot Settings" key="2"> <TimeSlotSettings /></TabPane>
                            <TabPane tab="Zip Code Settings" key="3"> <ZipCodeSettings /></TabPane>
                        </Tabs>
                    </div>
                </Col>

            </Row>
        </>
    );
};
export default SettingsScreen;