var urls = {
    getUrls: {
        driver: '/superadmin/drivers',
        merchant: '/superadmin/merchants',
        deliveryRequest: '/superadmin/deliveryRequests',
        settings: '/superadmin/settings',
        timeSlotSettings: '/superadmin/timeslotsettings',
        zipCodeSettings: '/superadmin/zipcodesettings',
        "driverpayments": "/superadmin/driver/payments",
        "contactus": "/superadmin/contactus"


    },
    postUrls: {
        token: '/admin/token',
        logout: '/admin/logout',
        "deleteMerchant": "/superadmin/merchants/delete",
        "deleteDriver": "/superadmin/drivers/delete",
        settings: '/superadmin/settings',
        timeSlotSettings: '/superadmin/timeslotsettings',
        zipCodeSettings: '/superadmin/zipcodesettings',
        calculatePayments: '/superadmin/driver/calculatepayments'




    },
    putUrls: {
        "driverStatus": "/superadmin/drivers",
        "merchantStatus": "/superadmin/merchants",
        timeSlotSettings: '/superadmin/timeslotsettings',
        zipCodeSettings: '/superadmin/zipcodesettings',

        "driverpayments": "/superadmin/driver/payments",
        "deliveryRequest": '/superadmin/deliveryRequests',
    },
}

export default urls