import { Tag, Switch } from 'antd'

var renders = {
    bool: bool => <Switch disabled checked={bool} />
}

const generateRenders = (arr) => {
    var newarr = []
    for (let i = 0; i < arr.length; i++) {
        const type = arr[i].type;
        var r, obj;
        if (!arr[i].showInTable) {
            continue
        }
        switch (type) {
            case "enum":
                r = (val) => {
                    var color = arr[i].colors[val];
                    return (
                        <Tag color={color} >
                            {val}
                        </Tag>
                    );
                }
                break;
            default:
                r = renders[type]
                break;

        }
        if (r) {
            if (!arr[i].render) {
                arr[i].render = r;
            }

        }
        obj = arr[i]
        newarr.push(obj)

    }
    return newarr;
}

export { generateRenders }